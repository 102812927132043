<template>
  <mdb-container>
    <Adminheader></Adminheader>
    <section class="mt-3">
      <mdb-row>
        <mdb-col xl="4" md="4" class="mb-4">
          <mdb-card>
            <mdb-card-header color="orange darken-3">Administration</mdb-card-header>
            <mdb-card-body>
              <mdb-list-group>
                <mdb-list-group-item action icon="user-shield" @click.native="gotoadminlist">Gestion des Administrateurs</mdb-list-group-item>
                <mdb-list-group-item action icon="project-diagram" @click.native="gotoagencylist">Liste des agences</mdb-list-group-item>
                <mdb-list-group-item action icon="video" @click.native="gotowebinarlist">Liste de tous les webinaires</mdb-list-group-item>
              </mdb-list-group>
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
        <mdb-col xl="8" md="8" class="mb-4">
          <mdb-card>
            <mdb-card-header color="green darken-3">Prochains webinaires prévus ou en cours</mdb-card-header>
            <mdb-card-body>
              <mdb-alert color="info" class="text-center" v-if="futurwebinars.length === 0">
                <h3>Aucun webinaire à venir!</h3>
              </mdb-alert>
              <mdb-tbl v-else>
                <mdb-tbl-body>
                  <tr v-for="futurwebinar in futurwebinars" :key="futurwebinar.ident">
                    <td><mdb-icon icon="microphone-alt" v-if="futurwebinar.onair" alt="ON AIR!" /> {{ futurwebinar.name }}<div v-if="!futurwebinar.ispublic"><strong>TEST</strong></div></td>
                    <td>{{ futurwebinar.eventdate }}</td>
                    <td>
                      <mdb-dropdown>
                        <mdb-dropdown-toggle color="ins" slot="toggle" size="sm" tag="a" style="width:110%;" >Action</mdb-dropdown-toggle>
                        <mdb-dropdown-menu color="ins">
                          <mdb-dropdown-item v-on:click="gotostatswebinar(futurwebinar.ident)">Statistiques / infos</mdb-dropdown-item>
                          <mdb-dropdown-item v-on:click="gotowebsite(futurwebinar.permalink)">Voir sur le site</mdb-dropdown-item>
                          <div class="dropdown-divider"></div>
                          <mdb-dropdown-item v-on:click="gotoeditwebinar(futurwebinar.ident)"><strong>Modifier</strong></mdb-dropdown-item>
                        </mdb-dropdown-menu>
                      </mdb-dropdown>
                    </td>
                  </tr>
                </mdb-tbl-body>
              </mdb-tbl>
              <hr />
              <div>
                <em style="text-decoration: underline;">Légende:</em><br />
                <mdb-icon icon="microphone-alt" alt="ON AIR!" /> Présentement en diffusion
              </div>
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
      </mdb-row>
    </section>
  </mdb-container>
</template>

<script>
    import Vue from 'vue';
    import Adminheader from '@/components/Adminheader.vue';

    import {
        mdbContainer,
        mdbRow,
        mdbCol,
        mdbCard,
        mdbCardHeader,
        mdbCardBody,
        mdbListGroup,
        mdbListGroupItem,
        mdbAlert,
        mdbTbl,
        mdbTblBody,
        mdbDropdown,
        mdbDropdownToggle,
        mdbDropdownMenu,
        mdbDropdownItem,
        mdbIcon,
    } from 'mdbvue';

    import Apicall from '../libs/Apicall';

    export default Vue.extend({
        name: 'Dashboard',
        components: {
            Adminheader,
            mdbContainer,
            mdbRow,
            mdbCol,
            mdbCard,
            mdbCardHeader,
            mdbCardBody,
            mdbListGroup,
            mdbListGroupItem,
            mdbAlert,
            mdbTbl,
            mdbTblBody,
            mdbDropdown,
            mdbDropdownToggle,
            mdbDropdownMenu,
            mdbDropdownItem,
            mdbIcon,
        },
        data() {
            return {
                dummy: '',
                futurwebinars: [],
            };
        },
        mounted() {
            this.getAndDisplayFuturesWebinars();
        },
        methods: {
            test() {
                console.log('test');
            },
            gotoagencylist() {
                alert('a implémenter');
            },
            gotoadminlist() {
                this.$router.push({ name: 'Adminlist' });
            },
            gotoeditwebinar(_ident) {
                this.$router.push({ name: 'Viewwebinaire', params: { ident: _ident } });
            },
            gotostatswebinar(_ident) {
                this.$router.push({ name: 'Statswebinaire', params: { ident: _ident } });
            },
            gotowebinarlist() {
                this.$router.push({ name: 'Webinairelist' });
            },
            gotowebsite(permalink) {
                window.location.href = `/webinaire/${permalink}`;
            },
            getAndDisplayFuturesWebinars() {
                const self = this;
                const api = new Apicall();
                api.getFuturesWebinarAdmin().then((response) => {
                    self.futurwebinars = response.data.webinars;
                });
            },
        },
    });
</script>
